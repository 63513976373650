import React, { FunctionComponent } from 'react'
import { Link, PageProps, graphql, navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

import { WpInstructor, WpSimpleProductConnection } from '../../../graphql-types'

import { FlexibleContent } from '../../components/flexibleContent/FlexibleContent'
import { Layout } from '../../components/layout'
import { ProductList } from '../../components/productList'

import { PRODUCTS } from '../../graphql/query/product'

interface IDataProps {
  wpInstructor: WpInstructor
}

const InstructorTemplate: FunctionComponent<PageProps<IDataProps>> = ({
  data,
}) => {
  const {
    wpInstructor: { flexibleContent, id, instructorInfo, title },
  } = data

  const {
    loading: loadingProducts,
    error: errorProducts,
    data: dataProducts,
  } = useQuery<{ products: WpSimpleProductConnection }>(PRODUCTS, {
    variables: { where: { stockStatus: 'IN_STOCK' } },
  })

  return (
    <Layout>
      <Container maxW="4xl">
        <Heading as="h1" size="xl" color="msuGreen">
          {title}
        </Heading>
        <Text>{instructorInfo?.bio}</Text>
        <Grid bgColor="blackAlpha.100" gridGap="2" p="4">
          <Heading size="md" color="msuGreen">
            Course Accreditations
          </Heading>
          <Flex gridGap="2" wrap="wrap">
            {instructorInfo?.trained?.map((item, i) => {
              return item?.uri ? (
                <Link key={i} to={item?.uri}>
                  <Badge colorScheme="blackAlpha">{item?.title}</Badge>
                </Link>
              ) : (
                <Badge key={i}>{item?.title}</Badge>
              )
            })}
          </Flex>
        </Grid>
        <FlexibleContent {...flexibleContent} />
      </Container>

      <Container maxW="4xl">
        <Grid gridGap="4">
          <Heading size="lg">Upcoming dates</Heading>
          <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
            <Grid gridGap="4">
              {dataProducts?.products.edges
                .filter(({ node: { courseDetails } }) => {
                  return courseDetails?.instructor?.id === id
                })
                .slice(0, 2)
                .map(({ node }, i) => {
                  return <ProductList key={i} {...node} />
                })}
              <Flex justifyContent="flex-end">
                <Button
                  colorScheme="msuGreen"
                  minW="32"
                  onClick={() => {
                    navigate(`/products?instructor=${id}`)
                  }}
                  variant="outline"
                >
                  Find more dates
                </Button>
              </Flex>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default InstructorTemplate

export const query = graphql`
  query instructorPage($id: String) {
    wpInstructor(id: { eq: $id }) {
      flexibleContent {
        content {
          ... on WpInstructor_Flexiblecontent_Content_Simple {
            align
            fieldGroupName
            heading
            headingStyle
            link
            subheading
            subheadingStyle
            text
          }
          ... on WpInstructor_Flexiblecontent_Content_Quote {
            fieldGroupName
            quote
            caption
          }
          ... on WpInstructor_Flexiblecontent_Content_Image {
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpInstructor_Flexiblecontent_Content_Columns {
            fieldGroupName
            template
            columnOne {
              ... on WpInstructor_Flexiblecontent_Content_Columns_ColumnOne_Simple {
                align
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpInstructor_Flexiblecontent_Content_Columns_ColumnOne_Quote {
                caption
                fieldGroupName
                quote
              }
            }
            columnTwo {
              ... on WpInstructor_Flexiblecontent_Content_Columns_ColumnTwo_Simple {
                align
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpInstructor_Flexiblecontent_Content_Columns_ColumnTwo_Quote {
                caption
                fieldGroupName
                quote
              }
            }
          }
        }
      }
      instructorInfo {
        bio
        trained {
          ... on WpCourse {
            id
            title
            uri
          }
        }
      }
      id
      slug
      title
    }
  }
`
